import axios from "axios";
import router from "./../router";

export default {
  namespaced: true,
  state: {
    lessons: null,
    lessonsAudio: null,
    lessonsDocs: null,
    orientation_videos: null,
    currentLesson: null,
    watchedLessons: [],
    badges: [],
    userStreak: { streak: 0, progress: 0 },
    takenQuiz: null, // store array of all taken quiz
    allQuizScore: null, // store array of all taken quiz
    learningType: "learning", // ['learning','jobberman','orientation']
    showEnrollPrompt: true,
    showUpgradeModal: true,
    showBootcampNotice: true,
    showOnboardModal: true,
    progress: 0
  },

  getters: {
    lessons(state) {
      return state.lessons;
    },
    lessonsAudios(state) {
      return state.lessonsAudio;
    },
    lessonsDocs(state) {
      return state.lessonsDocs;
    },
    orientation_videos(state) {
      return state.orientation_videos;
    },
    currentLesson(state) {
      return state.currentLesson;
    },
    watchedLessons(state) {
      return state.watchedLessons;
    },
    badges(state) {
      return state.badges;
    },
    userStreak(state) {
      return state.userStreak;
    },
    progress(state) {
      return state.progress;
    },
    takenQuiz(state) {
      return state.takenQuiz;
    },
    allQuizScore(state) {
      return state.allQuizScore;
    },
    learningType(state) {
      return state.learningType;
    },
    showEnrollPrompt(state) {
      return state.showEnrollPrompt;
    },
    showBootcampNotice(state) {
      return state.showBootcampNotice;
    },
    showOnboardModal(state) {
      return state.showOnboardModal;
    },
    showUpgradeModal(state) {
      return state.showUpgradeModal;
    },
  },

  mutations: {
    SET_LESSONS_AUDIO(state, lessons) {
      state.lessonsAudio = lessons;
    },
    SET_LESSONS_DOCS(state, lessons) {
      state.lessonsDocs = lessons;
    },
    SET_LESSONS(state, lessons) {
      state.lessons = lessons;
    },
    SET_ORIENTATION_VIDEOS(state, orientation_videos) {
      state.orientation_videos = orientation_videos;
    },
    SET_CURRENT_LESSON(state, currentLesson) {
      state.currentLesson = currentLesson;
    },
    SET_WATCHED_LESSONS(state, watchedLessons) {
      state.watchedLessons = watchedLessons;
    },
    SET_STREAK(state, userStreak) {
      state.userStreak = userStreak;
    },
    SET_PROGRESS(state, progress) {
      state.progress = progress;
    },
    SET_BADGES(state, badges) {
      state.badges = badges;
    },
    SET_TAKEN_QUIZ(state, takenQuiz) {
      state.takenQuiz = takenQuiz;
    },
    SET_QUIZ_SCORE(state, allQuizScore) {
      state.allQuizScore = allQuizScore;
    },
    SET_LEARNING_TYPE(state, learningType) {
      state.learningType = learningType;
    },
    SET_ENROLL_PROMPT(state, showEnrollPrompt) {
      state.showEnrollPrompt = showEnrollPrompt;
    },
    SET_BOOTCAMP_NOTICE(state, showBootcampNotice) {
      state.showBootcampNotice = showBootcampNotice;
    },
    SET_ONBOARD_MODAL(state, showOnboardModal) {
      state.showOnboardModal = showOnboardModal;
    },
    SET_UPGRADE_MODAL(state, showUpgradeModal) {
      state.showUpgradeModal = showUpgradeModal;
    },
  },

  actions: {
    
    async fetchLessons({ commit, state }) {
      if (true) {
        try {
          const { data } = await axios.get("v2/auth/lessons");
          // ensure sorting of lessons by week 
          let lessons = data.data.lessons;
          // lessons = lessons.sort((a,b)=> a.order - b.order)
          const videos = lessons.filter((les) => {
            // Check if there is at least one module with a format of "video"
            return les.modules.some((module) => module.format == "video");
          });
          const audios = lessons.filter((les) => {
            // Check if there is at least one module with a format of "audio"
            return les.modules.some((module) => module.format == "audio");
          });
          const documents = lessons.filter((les) => {
            // Check if there is at least one module with a format of "doc"
            return les.modules.some((module) => module.format == "doc");
          });
          const badges = data.data?.badges;
          
          commit("SET_LESSONS", videos);
          commit("SET_LESSONS_AUDIO", audios);
          commit("SET_LESSONS_DOCS", documents);
          commit("SET_BADGES", badges);
        } catch (error) {
          console.log(error);
        }
      } else {
        return;
      }
    },
    async storeLessons({ commit }, lessons) {
      commit("SET_LESSONS", lessons);
    },
    storeOrientationVideos({ commit }, videos) {
      commit("SET_ORIENTATION_VIDEOS", videos);
    },
    setCurrentLesson({ commit, state, rootState }, currentLesson) {
      if (rootState.auth.user.batch?.can_access != 1) {
        // setup guard for users auditing the course
        const lessons = state.lessons;
        const lessonIndex = lessons?.findIndex((item)=> {
          return item?.id == currentLesson?.id;
        })
        if (lessonIndex > 1) {
          let firstModuleOneLesson = lessons.find((item) => {
            return item.week == 1;
          })
          swal({
            title: "Make Payment",
            text: `You can only access a few lessons while auditing this course, make payment for full access.`,
            button: `Pay Now`
          }).then((res) => {
            if (res) return router.push({name: 'ApplicationPayment', params: { slug: rootState.auth.user.batch?.learning_type == 'instructor' ? 'hands-on' : 'self_paced' }});
          });
          return commit("SET_CURRENT_LESSON", firstModuleOneLesson);          
        }
      }
      commit("SET_CURRENT_LESSON", currentLesson);
    },
    storeLessonsAudio({ commit }, lessonsA) {
      commit("SET_LESSONS_AUDIO", lessonsA);
    },
    storeLessonsDoc({ commit }, lessonsD) {
      commit("SET_LESSONS_DOCS", lessonsD);
    },
    setBages({ commit }, badges) {
      commit("SET_BADGES", badges);
    },
    /**
     * Action to get all taken quizzes and store
     * @return [array]
     */
    async setTakenQuiz({ commit }) {
      try {
        axios
          .get("auth/quizzes/submitted")
          .then((res) => {
            const allQuiz = res.data.payload.map((quiz) => quiz.week);
            const allQuizScore = res.data.payload;
            commit("SET_TAKEN_QUIZ", allQuiz);
            commit("SET_QUIZ_SCORE", allQuizScore);
          })
          .catch((err) => {
            commit("SET_TAKEN_QUIZ", null);
          });
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Fetch Array of all lessons Watched by user
     * @return [Array]
     */
    async saveWatchedLessons({ commit, dispatch }, watchedLessons) {
      if (watchedLessons) {
        commit("SET_WATCHED_LESSONS", watchedLessons);
        await dispatch("fetchUserStreak")
        return;
      }
      try {
        const { data } = await axios.get("v2/auth/lessons/progress")
        commit("SET_WATCHED_LESSONS", data.data);
        await dispatch("fetchUserStreak")
        
      } catch (error) {
        commit("SET_WATCHED_LESSONS", []);
        console.log(error);
      }
    },
    async fetchUserStreak({ commit }) {
      try {
        const { data } = await axios.get("v2/auth/streaks")
        commit("SET_STREAK", data.data);
      } catch (error) {
        console.log(error);
        commit("SET_STREAK", { streak: 0, progress: 0 });
      }
    },
    setLearningType({ commit }, learningType) {
      commit("SET_LEARNING_TYPE", learningType);
    },
    setEnrollPrompt({ commit }, showEnrollPrompt) {
      commit("SET_ENROLL_PROMPT", showEnrollPrompt);
    },
    setBootcampNotice({ commit }, showBootcampNotice) {
      console.log(showBootcampNotice)
      commit("SET_BOOTCAMP_NOTICE", showBootcampNotice);
    },
    setOnboardModal({ commit }, showOnboardModal) {
      commit("SET_ONBOARD_MODAL", showOnboardModal);
    },
    setUpgradeModal({ commit }, showUpgradeModal) {
      commit("SET_UPGRADE_MODAL", showUpgradeModal);
    },
  },
};
